import React, { useEffect, useState } from "react";
import BaseSection from "../../BaseSection";

import Responsable from "../../../Responsable";
import styled from "styled-components";
import { color, cssTemplate, grid, media } from "../../../../constants/styles";

import { ReactComponent as Yes } from "../../../../svgs/yes.svg";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import { yesLink } from "../../../../constants/ref";
import Text from "./Text";
import { useObserver } from "../../../../hooks/useObserver";
import Button from "../../../Button";
import { useNavigate } from "react-router-dom";
import routes from "../../../../constants/routes";

interface LastSectionProps {}

const LastSection: React.FC<LastSectionProps> = (props) => {
  const { ref, isIntersecting } = useIntersecting();
  const nav = useNavigate();
  const [isInteracted, setIsInteracted] = useState<boolean>(false);

  const handleClickButton = (e: React.MouseEvent) => {
    e.preventDefault();
    nav(routes.BUSINESS);
  };

  useEffect(() => {
    if (!isInteracted && isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);
  return (
    <Container>
      <SectionWrapper>
        <ContentWrapper>
          <Text
            smallText="You Imagine, We Create"
            bigText={
              <Responsable
                pc={
                  <>
                    아이디어를 현실로
                    <br />
                    만들어드립니다.
                  </>
                }
                tablet={
                  <>
                    아이디어를 현실로
                    <br />
                    만들어드립니다.
                  </>
                }
                mobile={
                  <>
                    {" "}
                    아이디어를 현실로
                    <br />
                    만들어드립니다.
                  </>
                }
              />
            }
          />
        </ContentWrapper>
      </SectionWrapper>
    </Container>
  );
};

export default LastSection;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 160px;
  isolation: isolate;
  // max-width: 1920px;
  min-height: 830px;
  width: 100%;

  padding: 200px 0px;
  /* width: 100%;
  height: calc(var(--vh, 1vh) * 100);; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/image/webp/home/FooterImg.webp");
  ${media.TABLET} {
    // min-height: ${window.innerHeight - 80}px;
    min-height: 590px;
    background-position: revert;
    background-image: url("/image/webp/home/FooterImg-Tablet.webp");
  }
  ${media.MOBILE} {
    // min-height: ${window.innerHeight - 60}px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 60px;
  ${media.MOBILE} {
    gap: 40px;
  }
`;
