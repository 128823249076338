import styled from "styled-components";
import Responsable from "../Responsable";
import Button from "../Button";
import { media } from "../../constants/styles";

const FloatingButtons = () => {
  return (
    <Container>
      <FloatingWrapper>
        <Responsable
          pc={
            <Button
              isAnimated={false}
              label="견적 문의하기"
              onClick={() =>
                window.open("https://www.pluuug.com/form/Z36nX6BWeY", "_blank")
              }
              // wide
            />
          }
          tablet={
            <Button
              isAnimated={false}
              label="견적 문의하기"
              onClick={() =>
                window.open("https://www.pluuug.com/form/Z36nX6BWeY", "_blank")
              }
              // wide
            />
          }
          mobile={
            <Button
              isAnimated={false}
              label="견적 문의하기"
              onClick={() =>
                window.open("https://www.pluuug.com/form/Z36nX6BWeY", "_blank")
              }
              // wide
            />
          }
        />
      </FloatingWrapper>
    </Container>
  );
};

export default FloatingButtons;

const Container = styled.div`
  width: 100%;
  position: absolute;
  height: calc(100% - 190px);
  top: 0;
  pointer-events: none;

  ${media.TABLET} {
    height: calc(100% - 90px);
  }

  ${media.MOBILE} {
    height: calc(100% - 160px);
  }
`;

const FloatingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  position: sticky;
  width: 100%;
  height: 100px;
  top: calc(100vh - 120px);
  margin-bottom: 20px;
  pointer-events: auto;

  ${media.MOBILE} {
    top: calc(var(--vh, 1vh) * 100 - 120px);
    margin-bottom: 0;
  }
`;
