import React, { PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useObserver } from "../../hooks/useObserver";
import Footer from "./Footer";
import MobileMenu from "./MobileMenu";
import Navigation from "./Navigation";

interface LayoutProps extends PropsWithChildren {
  initialScrollElement?: HTMLDivElement | null;
}

const Layout: React.FC<LayoutProps> = (props) => {
  useObserver({ threshold: 0.3 });
  const [menuVisible, setMenuVisible] = useState(false);
  const location = useLocation();
  const [maxVh, setMaxVh] = useState(window.innerHeight * 0.01);
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  const handleClickMobileMenu = (e?: React.MouseEvent) => {
    e?.preventDefault();
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight * 0.01);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);

    handleResize(); // 초기 실행

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [vh]);

  return (
    <>
      <Navigation menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
      <Body>{props.children}</Body>
      <Footer />
      {menuVisible && <MobileMenu buttonHandler={handleClickMobileMenu} />}
    </>
  );
};

export default Layout;

const Body = styled.main`
  position: relative;
  box-sizing: border-box;
  color: #1e1e1e;
  background-color: #fff;
`;
