import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import styled, { keyframes } from "styled-components";
import { detailKeyValueState } from "../../constants/recoil";
import { animations, media } from "../../constants/styles";

import { ReactComponent as Logo } from "../../svgs/logo.svg";
import Responsable from "../Responsable";
import { maxWidth } from "../sections/shared/styled";
import Menu from "./Menu";
import MenuButton from "./MenuButton";
import { ReactComponent as LogoBar } from "../../svgs/logo-bar.svg";
import Button from "../Button";

interface NavigationProps {
  menuVisible: boolean;
  setMenuVisible: (menuVisible: boolean) => void;
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const { isModalOpen } = useRecoilValue(detailKeyValueState);
  const [isFixed, setIsFixed] = useState(false);
  // const backgroundColor = useRecoilValue(backgroundColorState);
  const router = useLocation();
  const activeMenu = router.pathname;
  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const [headerColor, setHeaderColor] = useState("rgba(17,17,17,0)");

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        setIsFixed(true);
      }, 1000);
    } else {
      setIsFixed(false);
    }
  }, [isModalOpen]);

  const scrollHandleEvent = () => {
    // console.log(window.scrollY);
    const currentScrollY = window.scrollY;

    if (currentScrollY > 420) {
      setHeaderColor("rgba(17,17,17,1)");
    } else {
      setHeaderColor("rgba(17,17,17,0)");
    }
    // if (headerWrapperRef.current) {
    //   // 화면 높이
    //   const viewHeight = window.innerHeight;
    //   // 현재 위치
    //   const scrollLocation = document.documentElement.scrollTop;

    //   // 홈 이미지 영역 끝나는 위치
    //   let currentHomeSectionMediaHeight = 0;

    //   // home section2 screen Type에 따라서 height을 다르게 측정한다.
    //   document
    //     .querySelectorAll(".homeSection1Container")
    //     .forEach((container) => {
    //       const containerHeight = container.clientHeight;
    //       if (containerHeight === 0) return;
    //       currentHomeSectionMediaHeight = containerHeight;
    //     });

    //   //  header background 스타일 변경
    //   if (activeMenu === "/") {
    //     const mainTextHideStartPoint = scrollLocation >= viewHeight / 3;

    //     headerWrapperRef.current.style.backgroundColor = `rgba(17,17,17,1)`;
    //     if (
    //       mainTextHideStartPoint &&
    //       scrollLocation < currentHomeSectionMediaHeight - viewHeight
    //     ) {
    //       console.log("top");
    //       headerWrapperRef.current.style.backgroundColor = `rgba(17,17,17,${0})`;
    //     } else if (
    //       scrollLocation >=
    //       currentHomeSectionMediaHeight - viewHeight
    //     ) {
    //       console.log("top 2");
    //       headerWrapperRef.current.style.backgroundColor = `rgba(17,17,17,${
    //         (scrollLocation - currentHomeSectionMediaHeight) * 0.001 + 1
    //       })`;
    //     }
    //   } else if (activeMenu === "/team" || activeMenu === "/recruit") {
    //     console.log("top 3");
    //     const headerWrapperActiveHeight = (scrollLocation - viewHeight) * 0.001;
    //     headerWrapperRef.current.style.backgroundColor = `rgba(17,17,17,${headerWrapperActiveHeight})`;
    //   } else if (activeMenu === "/business") {
    //     console.log("top 4");
    //     headerWrapperRef.current.style.backgroundColor = `rgba(17,17,17,${
    //       1 + -(viewHeight - scrollLocation) / 1000
    //     })`;
    //     // (scrollLocation - viewHeight + scrollLocation) * 0.001 + 0.5
    //   }
    // }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandleEvent);
    return () => window.removeEventListener("scroll", scrollHandleEvent);
  }, []);

  return (
    <Wrapper
      ref={headerWrapperRef}
      backGroundColor={headerColor}
      isDetailOpen={isFixed}
    >
      <StyledNav>
        <Link to={"/"}>
          {!props.menuVisible && (
            <StyledLogo />
            // <div>
            //   <Logo2>
            //     <MovingArrow
            //       direction="down"
            //       src="/image/webp/icons/logo-bar.svg"
            //     />
            //     <MovingArrowUp
            //       direction="up"
            //       src="/image/webp/icons/logo-bar.svg"
            //     />
            //   </Logo2>

            //   <img src="/image/webp/icons/SLOGUP.webp" />
            //   <StyledLogo />
            // </div>
          )}
        </Link>
        <Responsable
          pc={<Menu />}
          tablet={
            <>
              <MenuButton
                menuVisible={props.menuVisible}
                onClick={() => {
                  props.setMenuVisible(!props.menuVisible);
                }}
              />
            </>
          }
          mobile={
            <>
              <MenuButton
                menuVisible={props.menuVisible}
                onClick={() => {
                  props.setMenuVisible(!props.menuVisible);
                }}
              />
            </>
          }
        />

        <StyledPcButton
          style={{
            height: "44px",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "22px",
            padding: "11px 18px",
          }}
          label="견적 문의하기"
          isAnimated={false}
          onClick={() =>
            window.open("https://www.pluuug.com/form/Z36nX6BWeY", "_blank")
          }
        />
      </StyledNav>
    </Wrapper>
  );
};

export default Navigation;

const StyledLogo = styled(Logo)`
  width: 150px;
  height: 32px;
  ${media.MOBILE} {
    width: 100%;
    max-width: 136px;
  }
`;

const Logo2 = styled.div`
  display: inline-block;
  position: relative;
  // min-width: 90.51px;
  margin-right: 18px;
  // height: 108px;
  z-index: 200;
  // margin-top: 32px;
  // margin-left: 16px;

  > svg {
    position: absolute;
    width: 36.27px;
    height: 20.01px;
    &:nth-child(1) {
      top: -22px;
      left: 0;
      animation: ${animations.firstArrow} 0.8s ease-in-out;
    }

    &:nth-child(2) {
      bottom: -10px;
      right: 50px;
      animation: ${animations.secondArrow} 0.8s ease-in-out;
    }
  }
`;

const ArrowDownIcon = styled.img<{
  direction: "up" | "down" | "left" | "right";
}>`
  width: 16.62px;
  height: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 1;
  top: -24px;
  ${media.TABLET} {
    bottom: 73px;
  }

  ${({ direction }) =>
    direction === "up" &&
    ` 
      left: 0;
      bottom: 0;
      transform: translateX(-50%) translateY(10%);
      
    `}

  ${({ direction }) =>
    direction === "down" &&
    `
    top: -14px;
      left: 3px;
      right: 0px;
      transform: translateX(-50%);
    `}

  ${({ direction }) =>
    direction === "left" &&
    `
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(-90deg);
    `}

  ${({ direction }) =>
    direction === "right" &&
    `
      top: 50%;
      left: 0;
      transform: translateY(-50%) rotate(90deg);
    `}
`;

const moveArrow = keyframes`
  0% {
    transform: translateX(-50%) translateY(20%);
    opacity: 1;
  }
  50% {
    // transform: translateX(-50%) translateY(-75%);
  }
  100% {
    opacity: 1;
    // transform: translateX(-50%) translateY(-50%);
  }
`;
const moveArrowUp = keyframes`
  0% {
    transform: translateX(-50%) translateY(-20%);
    opacity: 1;
  }
  50% {
    // transform: translateX(-50%) translateY(-75%);
  }
  100% {
    opacity: 1;
    // transform: translateX(-50%) translateY(-50%);
  }
`;

export const MovingArrow = styled(ArrowDownIcon)`
  animation: ${moveArrow} 2s ease-in-out 1;
`;

export const MovingArrowUp = styled(ArrowDownIcon)`
  animation: ${moveArrowUp} 2s ease-in-out 1;
`;

const StyledNav = styled.nav<{ isDetailOpen?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: transparent;
  color: white;

  position: relative;

  width: 100%;

  transition: 0.8s top;
  ${maxWidth};

  ${media.TABLET} {
    max-width: 100%;
    padding: 0 0px;
  }

  ${media.MOBILE} {
    max-width: 100%;
    padding: 0 0px;
  }
`;
const Wrapper = styled.div<{ isDetailOpen?: boolean; backGroundColor: string }>`
  padding: 0px 48px;
  position: fixed;
  top: ${({ isDetailOpen }) => (isDetailOpen ? "-10vh" : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 60;
  width: 100%;
  height: 60px;
  background-color: ${({ backGroundColor }) => backGroundColor};
  transition: 0.3s;
  ${media.MOBILE} {
    padding: 0 16px;
    height: 60px;
  }
`;

const StyledPcButton = styled(Button)`
  height: 44px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  padding: 11px 18px;

  ${media.TABLET} {
    display: none;
  }
`;
