import React, { useState } from "react";
import styled from "styled-components";
import { BUSINESS_CARD_INTERVAL } from "../../../../constants/animationValue";
import { consultingServices } from "../../../../constants/consultingServices";
import outSourcingServices, {
  outsourcingIcons,
} from "../../../../constants/outsourcingServices";
import { animations, cssTemplate, media } from "../../../../constants/styles";
import { valueIcons } from "../../../../constants/valueUpServices";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import useInterval from "../../../../hooks/useInterval";
import Responsable from "../../../Responsable";
import { maxWidth, Title } from "../../shared/styled";
import MobileSection from "../shared/MobileSection";
import PCSection from "../shared/PCSection";
import TabletSection from "../shared/TabletSection";

interface BusinessSection04Props {}

const BusinessSection04: React.FC<BusinessSection04Props> = (props) => {
  const mobileDescs = [
    `IT 영역엔 IT를 깊게 아는 사람이 아니면 맞닥뜨릴 수밖에 없는 시행착오가 많습니다. 훌륭한 사업 인데도 이 장벽을 넘지 못해 답답해하는 경우를 자주 만났습니다.`,
    `슬로그업이 시간과 비용을 아껴드릴 수 있습니다. IT 비즈니스의 BM, 가치, 기술, 구축 및 운영 계획과 가설 검증을 도와드리겠습니다.`,
    `테크 조직 빌딩이나 기술 스택 믹스에 관해서도 먼저 시행착오를 겪은 저희가 경험과 노하우를 나눠드릴 수 있습니다.`,
  ];
  const tabletDescs = [
    `IT 영역엔 IT를 깊게 아는 사람이 아니면 맞닥뜨릴 수밖에 없는 시행착오가 많습니다. 훌륭한 사업인데도 이 장벽을 넘지 못해 답답해하는 경우를 자주 만났습니다.`,
    `슬로그업이 시간과 비용을 아껴드릴 수 있습니다. IT 비즈니스의 BM, 가치, 기술, 구축 및 운영 계획과 가설 검증을 도와드리겠습니다.`,
    `테크 조직 빌딩이나 기술 스택 믹스에 관해서도 먼저 시행착오를 겪은 저희가 경험과 노하우를 나눠드릴 수 있습니다.`,
  ];
  const descs = [
    `IT 영역엔 IT를 깊게 아는 사람이 아니면 맞닥뜨릴 수밖에 없는 시행착오가 많습니다. 훌륭한 사업인데도 이 장벽을 넘지 못해 답답해하는 경우를 자주 만났습니다.`,
    `슬로그업이 시간과 비용을 아껴드릴 수 있습니다. IT 비즈니스의 BM, 가치,기술, 구축 및 운영 계획과 가설 검증을 도와드리겠습니다.`,
    `테크 조직 빌딩이나 기술 스택 믹스에 관해서도 먼저 시행착오를 겪은 저희가 경험과 노하우를 나눠드릴 수 있습니다.`,
  ];

  const handleClickButton = (e: React.MouseEvent) => {
    // e.preventDefault();
    // nav(routes.RECRUIT);
    // https://docs.google.com/forms/d/e/1FAIpQLScn90zgmVvJoe1VuzjM7joC-MDf4Ww5ey2Nh7XTxtLPtICX1g/viewform => https://www.pluuug.com/form/Z36nX6BWeY 로 변경 (구르미 / 2025-01-05)
    window.open("https://www.pluuug.com/form/Z36nX6BWeY", "_blank");
  };

  return (
    <Responsable
      pc={
        <PCSection
          titleH1={
            <>
              시간과 비용을 아낄
              <br />
              IT 컨설팅.
            </>
          }
          titleSpan="Consulting Service"
          buttonLabel="문의하기"
          buttionClickHandler={handleClickButton}
          descs={descs}
          // bottom={<Bottom />}
        />
      }
      tablet={
        <TabletSection
          titleH1={
            <>
              시간과 비용을 아낄
              <br />
              IT 컨설팅.
            </>
          }
          titleSpan="Consulting Service"
          buttonLabel="문의하기"
          buttionClickHandler={handleClickButton}
          descs={tabletDescs}
          // bottom={<Bottom />}
        />
      }
      mobile={
        <MobileSection
          titleH1={
            <>
              시간과 비용을 아낄
              <br />
              IT 컨설팅.
            </>
          }
          titleSpan="Consulting Service"
          buttonLabel="문의하기"
          buttionClickHandler={handleClickButton}
          descs={mobileDescs}
          // bottom={<Bottom />}
        />
      }
    />
  );
};

export default BusinessSection04;

// FIXME
const Bottom = () => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isIntersecting
      ? isInteracted > outsourcingIcons.length
        ? null
        : BUSINESS_CARD_INTERVAL
      : null
  );
  return (
    <BottomWrapper ref={ref}>
      {consultingServices.map((consultingService, index) => (
        <Card
          theme={{ isIntersecting: isInteracted >= index }}
          key={consultingService.title}
        >
          <div className="card-title">
            <span>{consultingService.title}</span>
            <Slash />
          </div>
          <div className="card-desc">{consultingService.desc}</div>
        </Card>
      ))}
    </BottomWrapper>
  );
};

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 100%;
  flex-wrap: nowrap;
  ${maxWidth};
  ${media.TABLET} {
    flex-wrap: wrap;
  }
`;
const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  gap: 24px;
  flex: 1;
  ${(props) => cssTemplate.fadeInOut(props)}
  /* Gray/Gray-02 */

  background: #1e1e1e;
  & > div.card-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height, or 138% */

    /* Gray/Gray-08 (White) */

    color: #ffffff;
  }
  & > div.card-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 19px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    /* Gray/Gray-05 */

    color: #dcdcdc;
  }
  ${media.TABLET} {
    flex: 0 0 326px;
    height: 240px;

    padding: 50px;
    gap: 24px;
    & > div.card-title {
      font-size: 32px;
      line-height: 44px;
    }
    & > div.card-desc {
      font-size: 16px;
      line-height: 24px;
    }
  }
  ${media.MOBILE} {
    flex: 0 0 calc(100%);
    height: 166px;
    padding: 32px;
    gap: 24px;
    & > div.card-title {
      font-size: 22px;
      line-height: 34px;
      gap: 12px;
    }
    & > div.card-desc {
      font-size: 15px;
      line-height: 22px;
    }
  }
`;
const Slash = styled.div`
  width: 21px;
  height: 0px;

  /* Primary/Neon Yellow Green 05 ⭐️ */

  border: 2px solid #b3ff01;
  transform: rotate(135deg);
`;
